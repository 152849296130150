import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <>
      <header id="site-header" className="fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light stroke py-lg-0">
            <h1>
              <Link className="navbar-brand pe-xl-5 pe-lg-4" to="index.html">
               <img src="/assets/images/logo.png" style={{height:'90px',position:'relative', top:"-15px"}}></img>
              </Link>
            </h1>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa icon-expand fa-bars" />
              <span className="navbar-toggler-icon fa icon-close fa-times" />
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav me-lg-auto my-2 my-lg-0 navbar-nav-scroll">
              <li className="nav-item">
                  
                </li>
                <li className="nav-item">
                  
                </li>
                <li className="nav-item">
                  
                </li>
                <li className="nav-item">
                  
                </li>
                <li className="nav-item">
                  
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/Home"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/About">
                    About
                  </Link>
                </li>
                <li class="dropdown nav-item">
                  <Link
                    class="nav-link dropdown-toggle"
                    
                    data-toggle="dropdown"
                  >
                    What We Do
                    <span class="caret"></span>
                  </Link>
                  <ul class="dropdown-menu" style={{transform:'translateX()'}}>
                    <li>
                      <Link to="/Sea" style={{fontSize:'16px'}}>Sea Freight </Link>
                    </li>
                    <li>
                      <Link to="/Air" style={{fontSize:'16px'}}>Air Freight </Link>
                    </li>
                    <li>
                      <Link to="/Road" style={{fontSize:'16px'}}>Road Transport</Link>
                    </li>
                    <li>
                      <Link to="/Warehousing" style={{fontSize:'16px'}}>Warehousing</Link>
                    </li>
                    <li>
                      <Link to="/Project" style={{fontSize:'16px'}}>Project Cargo</Link>
                    </li>
                    <li>
                      <Link to="/Supply" style={{fontSize:'16px'}}>Supply Chain Logistics</Link>
                    </li>
                   
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Contact">
                    Contact
                  </Link>
                </li>
                
              </ul>
              {/*/search-right*/}
              <ul className="header-search me-lg-4">
                <li className="get-btn">
                  <Link
                    to="/Getquote"
                    className="btn btn-style btn-primary"
                    title="search"
                  >
                    Request A Quote{" "}
                    <i className="fas fa-arrow-right ms-lg-4 ms-2" />
                  </Link>
                </li>
              </ul>
              {/*//search-right*/}
            </div>
            {/* toggle switch for light and dark theme */}
           
            {/* //toggle switch for light and dark theme */}
          </nav>
        </div>
      </header>

      <Outlet />
    </>
  );
};

export default Header;
