import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <section
        className="w3l-main-slider banner-slider position-relative w3-banner jarallax"
        id="home"
      >
        <video
          className="img-fluid"
          autoPlay
          muted
          loop
          style={{ zIndex: "1" }}
        >
          <source src="assets/images/video.mp4" type="video/mp4" />
        </video>
        <div className="owl-one owl-carousel owl-theme" style={{width:'-webkit-fill-available'}}>
          <div className="item">
            <div className="slider-info banner-view banner-top1">
              <div className="container">
                <div className="banner-info header-hero-19">
                  <h5>{companyname}</h5>
                  <h3 className="title-hero-19">
                  Fast, Reliable, and Secure Freight Solutions.
                  </h3>
                  <Link
                    to="/About"
                    className="btn btn-style btn-banner mt-sm-5 mt-4"
                  >
                    Read More <i className="fas fa-arrow-right ms-lg-4 ms-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //main-slider */}
      {/*/bottom-3-grids*/}
      <div className="w3l-3-grids py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          {/*/row-1*/}
          <div className="row mb-5 pb-lg-4">
            <div className="col-lg-12 pe-lg-4">
              <div className="title-content text-left">
                <h3 className="title-w3l">
                  
Efficient global freight forwarding services for your business{" "}
                </h3>
              </div>
            </div>
            
          </div>
          {/*//row-1*/}
          <div className="row pt-md-0 pt-5">
            <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
              <div className="grids3-info position-relative">
                <Link to="/Sea" className="d-block zoom">
                  <img
                    src="assets/images/sea.jpg"
                    alt=""
                    className="img-fluid news-image"
style={{height:'260px'}}
                  />
                </Link>
                <div className="w3-grids3-info">
                  <h6>
                    
                  </h6>
                  <h4 className="gdnhy-1">
                    <Link to="/Sea">Sea Freight</Link>
                    <Link className="w3item-link" to="/Sea">
                      <i className="fas fa-arrow-right ms-lg-4" />
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
              <div className="grids3-info position-relative">
                <Link to="/Air" className="d-block zoom">
                  <img
                    src="assets/images/air.jpg"
                    alt=""
                    className="img-fluid news-image"
style={{height:'260px'}}
                  />
                </Link>
                <div className="w3-grids3-info">
                  <h6>
                    
                  </h6>
                  <h4 className="gdnhy-1">
                    <Link to="/Air">Air Freight</Link>
                    <Link className="w3item-link" to="/Air">
                      <i className="fas fa-arrow-right ms-lg-4" />
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-md-0 mt-sm-4 px-lg-0">
              <div className="grids3-info position-relative">
                <Link to="/Road" className="d-block zoom">
                  <img
                    src="assets/images/road.jpg"
                    alt=""
                    className="img-fluid news-image"
style={{height:'260px'}}
                  />
                </Link>
                <div className="w3-grids3-info">
                  <h6>
                    
                  </h6>
                  <h4 className="gdnhy-1">
                    <Link to="/Road">Road Transport</Link>
                    <Link className="w3item-link" to="/Road">
                      <i className="fas fa-arrow-right ms-lg-4" />
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*//bottom-3-grids*/}
      {/*/features-section */}
      <section className="w3l-features py-5" id="work">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="title-content text-center mb-md-5 mb-4">
            <h6 className="title-subw3hny mb-1">Why Choose Us</h6>
            <h3 className="title-w3l mb-5">
            Your Cargo, Our Commitment.
            </h3>
          </div>
          <div className="main-cont-wthree-2 align-items-center text-center pt-lg-4">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-6">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                  <i class="fa fa-industry" aria-hidden="true"></i>
                  </div>
                  <h4>
                    <Link  className="title-head mb-3">
                    Distribution
                    </Link>
                  </h4>
                  <p className="text-para">
                  
Efficient distribution network for seamless freight forwarding.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-md-0 mt-5">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                  <i class="fa-solid fa-warehouse"></i>
                  </div>
                  <h4>
                    <Link className="title-head mb-3">
                    Fulfillment
                    </Link>
                  </h4>
                  <p className="text-para">
                  Streamlined fulfillment solutions for freight forwarding.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-0 mt-5">
                <div className="grids-1 box-wrap">
                  <div className="icon">
                  <i class="fa fa-truck"></i>
                  </div>
                  <h4>
                    <Link className="title-head mb-3">
                    Transportation
                    </Link>
                  </h4>
                  <p className="text-para">
                  Efficient transportation services for freight forwarding needs.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*//features section */}
      {/*/w3l-midslider*/}
      <section className="w3l-midslider py-5" id="midslider">
        {/* /grids */}
        <div className="container py-md-5">
          <div className="row">
            <div className="col-lg-7 stats_info-images pe-lg-5">
              <div className="stats_info-images-right row">
                <div className="col-12 mid-slider-content">
                  <img
                    src="assets/images/ab2.jpg"
                    className="img-fluid radius-image"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5 title-content text-left ps-lg-5 mt-lg-0 mt-5">
              <h6 className="title-subw3hny mb-1">About Us</h6>
              <h3 className="title-w3l mb-3">
                Delivering Confidence, Across the Globe.
              </h3>
              <p className="mb-3">
                At {companyname}, we understand that if your business involves
                selling goods, import and export play a critical role in its
                success. The complexities of international trade can be
                overwhelming, and that's where our expertise as an experienced
                freight forwarding company comes into play. We have in-depth
                knowledge of the import and export process, allowing us to
                navigate potential challenges and ensure smooth operations for
                our clients.
                <br />
              </p>
              <Link
                    to="/About"
                    className="btn btn-style btn-primary"
                    title="search"
                  >
                    Learn More{" "}
                    <i className="fas fa-arrow-right ms-lg-4 ms-2" />
                  </Link>
            </div>
            {/*/grids */}
          </div>
          {/*/grids */}
        </div>
      </section>
     
      <section className="w3l-blog">
        <div className="bloghome py-5" id="Newsblog">
          <div className="container py-lg-5 py-md-4 py-2">
            <div className="w3title-header text-center">
              <h6 className="title-subw3hny">Services</h6>
              <h3 className="title-w3l mb-5">
              Solving Logistics Challenges, Seamlessly.
              </h3>
            </div>
            <div className="row w3l-blogpost-content">
              <div className="col-lg-4 col-md-6 item mt-md-0 mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Warehousing" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/warehousing.jpg"
                        alt="blog-img"
                        style={{height:'250px'}}
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <div className="price-review d-flex justify-content-between mb-1 align-items-center">
                      <p>Services</p>
                    </div>
                    <Link to="/Warehousing" className="blog-desc">
                    Warehousing
                    </Link>
                  </div>
                  <div className="card-footer">
                    
                  </div>
                </div>
              </div>
             
              <div className="col-lg-4 col-md-6 item mt-md-0 mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Project" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/project.jpg"
                        alt="blog-img"
                        style={{height:'250px'}}
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <div className="price-review d-flex justify-content-between mb-1 align-items-center">
                      <p>Services</p>
                    </div>
                    <Link to="/Project" className="blog-desc">
                    Project Cargo
                    </Link>
                  </div>
                  <div className="card-footer">
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 item mt-md-0 mt-5">
                <div className="card">
                  <div className="card-header p-0 position-relative">
                    <Link to="/Supply" className="zoom d-block">
                      <img
                        className="card-img-bottom d-block"
                        src="assets/images/supply.jpg"
                        alt="blog-img"
                        style={{height:'250px'}}
                      />
                    </Link>
                  </div>
                  <div className="card-body blog-details">
                    <div className="price-review d-flex justify-content-between mb-1 align-items-center">
                      <p>Services</p>
                    </div>
                    <Link to="/Supply" className="blog-desc">
                    Supply Chain Solutions
                    </Link>
                  </div>
                  <div className="card-footer">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*//w3l-blog*/}
      

      <Footer />
    </>
  );
}
