import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title">Air Freight </h2>
                <p className="inner-page-para mt-2"></p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link href="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Air
                    Freight
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="w3l-services-1 py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          {/*/row-1*/}
          <div className="row mb-5 pb-lg-4">
            <div className="col-lg-6 p5-lg-4 services-1-left">
              <img
                src="assets/images/air.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="services-1-right col-lg-6 mt-lg-0 mt-md-5 mt-4 ps-lg-5">
              <div className="title-content text-left">
                <h6 className="title-subw3hny mb-1">Services </h6>
                <h3 className="title-w3l mb-3">Air Freight </h3>
              </div>
              <p className>
                Air freight forwarding is a highly efficient and speedy method
                of shipping goods, often utilizing commercial or passenger
                planes for transportation. It proves to be an excellent choice
                when dealing with lighter cargo that requires swift
                international delivery.
                <br />
                <br />
                Consider scenarios where the cargo, though not extensive, needs
                to be transported across borders urgently. In such cases, using
                commercial or passenger planes for air freight is the most
                viable option, ensuring a quick and reliable delivery.
                
              </p>
            </div>
          </div>
          {/*//row-1*/}
          <p className>
                As a valued client of {companyname}, you will have the added
                advantage of accessing air charters as an alternative air
                freight shipping solution. Air charters offer the fastest and
                most direct route for your shipments. With no layovers or
                external disruptions, your cargo travels seamlessly and swiftly
                to its destination.
                <br />
                <br />
                However, it is essential to note that air charters are a premium
                service and may incur higher costs compared to standard air
                freight options. While it guarantees the utmost speed and
                directness, the investment should be weighed against the urgency
                and specific needs of your shipment.
                <br />
                <br />
                Whether you opt for air freight services utilizing passenger
                planes or dedicated air cargo shipping, rest assured that{" "}
                {companyname} will deliver unparalleled efficiency and
                reliability. Our expert team is dedicated to tailoring solutions
                that match your cargo's unique requirements, ensuring your goods
                reach their destination swiftly and securely.
                <br />
                <br />
                By choosing {companyname} as your air freight forwarding
                partner, you gain access to a comprehensive range of options,
                each tailored to your specific needs. We are committed to
                providing the highest level of service, offering you peace of
                mind and confidence in your international shipping endeavors.</p>
                
        </div>
      </div>
      <Footer />
    </>
  );
}
