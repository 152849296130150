import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title">About {companyname} </h2>
                <p className="inner-page-para mt-2"></p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link href="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> About Us
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="w3l-midslider py-5" id="midslider">
        {/* /grids */}
        <div className="container py-md-5">
          <div className="row">
            <div className="col-lg-7 stats_info-images pe-lg-5">
              <div className="stats_info-images-right row">
                <div className="col-12 mid-slider-content">
                  <img
                    src="assets/images/ab2.jpg"
                    className="img-fluid radius-image"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5 title-content text-left ps-lg-5 mt-lg-0 mt-5">
              <h6 className="title-subw3hny mb-1">About Us</h6>
              <h3 className="title-w3l mb-3">
                Delivering Confidence, Across the Globe.
              </h3>
              <p className="mb-3">
                At {companyname}, we understand that if your business involves
                selling goods, import and export play a critical role in its
                success. The complexities of international trade can be
                overwhelming, and that's where our expertise as an experienced
                freight forwarding company comes into play. We have in-depth
                knowledge of the import and export process, allowing us to
                navigate potential challenges and ensure smooth operations for
                our clients.
                <br />
              </p>
             
            </div>
            {/*/grids */}
          </div>
          <p className="p-4">Many of our clients come to us with the struggle of keeping up with the demands of running their businesses. Import and export require meticulous planning, coordination, and compliance with various regulations, which can be time-consuming and resource-intensive for businesses to handle on their own. With our comprehensive freight forwarding services, we take the burden off our clients' shoulders, allowing them to focus on growing their businesses and seizing opportunities in the global market.<br/>

As a well-established freight forwarder services provider, we have built a solid reputation for reliability, efficiency, and excellence. Our dedicated team of professionals is committed to understanding our clients' unique requirements and tailoring our services to meet their specific needs. Whether it's managing documentation, customs clearance, or coordinating shipments across borders, we ensure a seamless and hassle-free experience for our clients.<br/>

At {companyname}, we have one common goal - to transform your business. By entrusting your import and export needs to us, you gain access to a wealth of expertise and resources that will streamline your logistics processes and enhance your supply chain. With our support, you can expand your reach to new markets, access a wider range of products, and optimize your inventory management for improved profitability.<br/>

Our commitment to excellence, attention to detail, and customer-centric approach set us apart as a trusted partner for businesses seeking to thrive in the global marketplace. Whether you are a seasoned importer or exporter or just starting your international trade journey, we are here to provide you with a complete suite of freight forwarding services that will empower your business to reach new heights of success.<br/></p>
          {/*/grids */}
        </div>
      </section>
      <Footer />
    </>
  );
}
