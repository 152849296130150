import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title">Supply Chain Logistics </h2>
                <p className="inner-page-para mt-2"></p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link href="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Supply
                    Chain Logistics
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="w3l-services-1 py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          {/*/row-1*/}
          <div className="row mb-5 pb-lg-4">
            <div className="col-lg-6 p5-lg-4 services-1-left">
              <img
                src="assets/images/supply.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="services-1-right col-lg-6 mt-lg-0 mt-md-5 mt-4 ps-lg-5">
              <div className="title-content text-left">
                <h6 className="title-subw3hny mb-1">Services </h6>
                <h3 className="title-w3l mb-3">Supply Chain Logistics </h3>
              </div>
              <p className>
                In today's rapidly evolving business landscape, staying ahead of
                the curve and embracing technological advancements is essential
                for sustained success. However, navigating the complexities of
                supply chain logistics and managing the intricate web of global
                supply networks can be a daunting task for businesses. That's
                where connecting with supply chain logistics experts becomes
                invaluable, ensuring that no links are missed in the supply
                chain process.
                <br />
                The role of supply chain management services extends beyond
                simply coordinating the movement of goods. It aims to gain a
                competitive advantage in the marketplace by enhancing
                operational efficiency, reducing costs, and improving overall
                performance. By leveraging effective supply chain logistics,
                businesses can enhance customer satisfaction and respond swiftly
                to changing market demands.
                <br />
              </p>
            </div>
          </div>
          {/*//row-1*/}
          <p className>
            At {companyname}, we have honed our expertise and developed proven
            methodologies in global supply chain management services. Our
            comprehensive approach involves meticulously analyzing your supply
            chain, identifying areas for improvement, and implementing tailored
            solutions to enhance efficiency and reduce bottlenecks.
            <br />
            We understand that each business is unique, and our supply chain
            logistics experts are adept at customizing strategies to fit your
            specific needs. From optimizing inventory management to streamlining
            transportation and distribution, we leave no stone unturned to drive
            value across your entire supply chain.
            <br />
            As a trusted partner, we help you navigate the complexities of
            global supply chains, mitigating risks, and capitalizing on emerging
            opportunities. Our team of professionals ensures seamless
            coordination between suppliers, manufacturers, and distributors,
            enabling you to stay agile in the face of dynamic market conditions.
            <br />
            By collaborating with {companyname}, you gain access to a wealth of
            knowledge and experience in supply chain logistics. Our dedication
            to excellence and commitment to understanding your unique challenges
            enable us to deliver optimal solutions that drive your business
            forward.
            <br />
            In the ever-evolving landscape of supply chain logistics, entrusting
            your operations to the expertise of {companyname} ensures that you
            stay ahead of the competition and remain at the forefront of
            industry advancements. Let us help you navigate the intricate world
            of supply chain management and propel your business towards
            sustained growth and success.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
