import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title">Contact Us </h2>
                <p className="inner-page-para mt-2">
                  
                </p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link href="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Contact
                    Us
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*//inner-page*/}
      <div className="map-iframe p-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60275.69726106052!2d72.9344066!3d19.2287584!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b92abc14627f%3A0x67321c4ebf19795e!2sLake%20City%20Mall!5e0!3m2!1sen!2sin!4v1690354023087!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {/* /contacts */}
      {/* contact-form 2 */}
      <section className="w3l-contact-2 py-5" id="contact">
        <div className="container py-lg-4 py-md-3 py-2">
          <div className="title-content text-center">
            <h6 className="title-subw3hny mb-1">Get in touch</h6>
            <h3 className="title-w3l mb-5">
              Get started by filling out the form below. We look forward to
              hearing from you.
            </h3>
          </div>
          <div className="contact-grids d-grid mt-5 pt-lg-3">
            <div className="contact-left">
              <div className="cont-details">
                <div className="cont-top margin-up">
                  <div className="cont-left text-center">
                    <span className="fas fa-map-marker-alt" />
                  </div>
                  <div className="cont-right">
                    <h6>Our head office address:</h6>
                    <p>{companyaddress}</p>
                  </div>
                </div>
                <div className="cont-top margin-up">
                  <div className="cont-left text-center">
                    <span className="fas fa-phone-alt" />
                  </div>
                  <div className="cont-right">
                    <h6>Call for help :</h6>
                    <p>
                      <a href="tel:{companynumber}">{companynumber}</a>
                    </p>
                  </div>
                </div>
                <div className="cont-top margin-up">
                  <div className="cont-left text-center">
                    <span className="far fa-envelope" />
                  </div>
                  <div className="cont-right">
                    <h6>Mail us:</h6>
                    <p>
                      <a href="mailto:{companyemail}" className="mail">
                        {companyemail}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-right">
              <form
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        className
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                      <span />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                    </div>
                  </div>
                  <div className="submit-w3l-button text-lg-right">
                    <button
                      name="submit"
                      className="btn btn-style btn-primary"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h1>{result}</h1>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
