import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title">Sea Freight </h2>
                <p className="inner-page-para mt-2"></p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link href="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Sea
                    Freight
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="w3l-services-1 py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          {/*/row-1*/}
          <div className="row mb-5 pb-lg-4">
            <div className="col-lg-6 p5-lg-4 services-1-left">
              <img
                src="assets/images/sea.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="services-1-right col-lg-6 mt-lg-0 mt-md-5 mt-4 ps-lg-5">
              <div className="title-content text-left">
                <h6 className="title-subw3hny mb-1">Services </h6>
                <h3 className="title-w3l mb-3">Sea Freight </h3>
              </div>
              <p className>
                Sea freight services have a rich history and remain a
                cost-effective and reliable option for international shipping.
                At our experienced freight forwarding company, sea freight is
                one of our specialties. We firmly believe that there is no
                one-size-fits-all approach when it comes to sea freight
                logistics. Each shipment is unique, and our team is dedicated to
                tailoring solutions that precisely meet our clients' needs.
                <br />
                <br />
                As an ocean freight logistics company, we take pride in the
                flexibility of our services. Unlike being tied to a single ocean
                carrier, our extensive network of partners allows us to offer a
                well-coordinated and balanced sea freight services portfolio.
                This means we can provide multiple options, ensuring that our
                clients have the freedom to choose the most suitable and
                cost-effective solution for their shipments.
              
               
              </p>
            </div>
          </div>
          {/*//row-1*/}
          <p className>   <br />
                <br />
                Transparency and open communication are fundamental to our
                approach. We aim to empower our clients with comprehensive
                knowledge of our sea freight services, allowing them to make
                informed decisions before committing to any shipment. Our expert
                team will guide them through the available options, offering
                insights and advice based on years of experience in the
                industry.
                <br /><br />
                When it comes to sea freight, trust in a reliable partner is
                crucial. With our extensive expertise and global network, we are
                dedicated to providing efficient and secure sea freight
                services. From small consignments to large-scale shipments, we
                handle each cargo with utmost care, ensuring timely delivery and
                smooth operations throughout the entire shipping process.
                <br />
                <br />
                With an unwavering commitment to customer satisfaction, we
                continue to be at the forefront of sea freight services,
                delivering value and excellence to our clients' supply chains.
                Whether it's for businesses seeking cost-effective shipping
                solutions or individuals looking to transport goods
                internationally, we stand ready to offer the most effective and
                personalized sea freight services available in the market.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}
