import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title">Air Freight </h2>
                <p className="inner-page-para mt-2">
                  
                </p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link href="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Project Cargo
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="w3l-services-1 py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          {/*/row-1*/}
          <div className="row mb-5 pb-lg-4">
            <div className="col-lg-6 p5-lg-4 services-1-left">
              <img src="assets/images/project.jpg" alt="" className="img-fluid radius-image" />
            </div>
            <div className="services-1-right col-lg-6 mt-lg-0 mt-md-5 mt-4 ps-lg-5">
              <div className="title-content text-left">
                <h6 className="title-subw3hny mb-1">Services </h6>
                <h3 className="title-w3l mb-3">Project Cargo</h3>
              </div>
              <p className>
              The world of project freight services is truly fascinating, encompassing the intricate logistics required to move extraordinary and oversized cargo across vast distances. From transporting space shuttles to delivering massive pipelines and rig components for gas and oil companies, these ventures demand specialized expertise and meticulous planning.<br/>

If your business operates in an industry that frequently undertakes such complex projects, partnering with a project cargo freight forwarder is essential. Our team of dedicated experts at {companyname} is equipped to provide customized solutions tailored to your unique requirements.<br/>

              </p>
              
            </div>
          </div>
          {/*//row-1*/}
          <p className>
            
Project cargo services go beyond conventional freight forwarding; they require a deep understanding of the specific challenges associated with moving extraordinary cargoes. With our vast experience and intricate knowledge of the industry, we can navigate the complexities of transporting heavy and oversized loads efficiently and securely.<br/>

From initial planning and strategizing to precise execution, our project cargo logistical experts are adept at orchestrating seamless operations. We carefully assess the cargo's specifications, transportation routes, and logistical requirements, leaving no room for error in the movement of your valuable assets.<br/>

At {companyname}, we recognize that each project is unique and demands a tailored approach. Our goal is to ensure your cargo reaches its destination on time and within budget, mitigating risks and maximizing efficiency throughout the entire process.<br/>

Partnering with a reliable project cargo freight forwarder can make all the difference in the success of your ventures. By choosing us, you gain access to a team of professionals who are well-versed in managing the intricacies of international project shipments.<br/>

When it comes to project freight services, there is no room for compromise. Our commitment to excellence and dedication to meeting your specific requirements set us apart as a trusted partner in handling your most challenging cargo movements.<br/>

So, if you find your business in need of extensive project cargo services, look no further than {companyname}. Our specialized solutions and industry-leading expertise will ensure your valuable cargo is in the safest and most capable hands, delivering a successful outcome for your ambitious projects.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
