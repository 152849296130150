import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title">Air Freight </h2>
                <p className="inner-page-para mt-2"></p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link href="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" />{" "}
                    Warehousing
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="w3l-services-1 py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          {/*/row-1*/}
          <div className="row mb-5 pb-lg-4">
            <div className="col-lg-6 p5-lg-4 services-1-left">
              <img
                src="assets/images/warehousing.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="services-1-right col-lg-6 mt-lg-0 mt-md-5 mt-4 ps-lg-5">
              <div className="title-content text-left">
                <h6 className="title-subw3hny mb-1">Services </h6>
                <h3 className="title-w3l mb-3">Warehousing </h3>
              </div>
              <p className>
                At our core, we believe in a comprehensive and integrated
                approach to warehouse operations, where the optimization of
                people, systems, and processes lies at the heart of everything
                we do. Our commitment to continuous improvement allows us to
                deliver unparalleled value to our customers' supply chains,
                leading to enhanced efficiencies and reduced costs.
                <br />
                One of our primary focuses is on our talented workforce. We
                understand that skilled and motivated employees are the backbone
                of any successful warehouse operation. Through rigorous training
                and development programs, we empower our team members to excel
                in their roles, enhancing their capabilities and fostering a
                culture of excellence. Our dedicated and proficient workforce is
                equipped to handle diverse challenges and deliver exceptional
                results.
                <br />
               
              </p>
            </div>
          </div>
          {/*//row-1*/}
          <p className> Equally critical is the seamless integration of cutting-edge
                systems and technologies. We leverage state-of-the-art warehouse
                management systems and advanced automation to streamline our
                processes, ensuring optimal inventory management, real-time
                tracking, and efficient order fulfillment. By harnessing the
                power of technology, we enhance visibility and control
                throughout the supply chain, allowing for quick decision-making
                and responsiveness to market demands.
                <br />
                Furthermore, we take a holistic view of warehouse processes.
                Through continuous evaluation and analysis, we identify areas
                for improvement and implement innovative solutions. This
                approach enables us to optimize workflows, minimize wastage, and
                reduce operational inefficiencies, ultimately driving down costs
                and improving overall performance.
                <br />
                By adopting a holistic approach, we consider every aspect of
                warehouse operations as interconnected elements that impact the
                overall supply chain. Our end-to-end solutions ensure seamless
                coordination and synchronization, enabling us to adapt swiftly
                to market changes and provide flexible, customer-centric
                services.
                <br />
                At the heart of our strategy is the unwavering commitment to our
                customers' success. We collaborate closely with our clients,
                understanding their unique requirements, and tailoring our
                warehouse operations to align with their specific needs. This
                customer-centric approach allows us to develop lasting
                partnerships built on trust and reliability.
                <br />
                As a result of our holistic approach, our customers experience
                streamlined supply chains, reduced lead times, improved
                inventory accuracy, and cost savings. We pride ourselves on
                being a strategic partner that not only meets but exceeds
                expectations, driving measurable value and growth for our
                clients' businesses.
                <br />
                In the dynamic and ever-evolving world of logistics, our
                dedication to continuous improvement and innovative practices
                sets us apart. We are committed to staying ahead of the curve,
                leveraging the latest industry trends and technologies to
                optimize warehouse operations and deliver exceptional results
                for our customers.
                <br />
                Choose us as your warehouse partner, and together, we will
                transform your supply chain, enhance efficiencies, and unlock
                the full potential of your business. Experience the power of a
                holistic approach to warehouse operations with us and propel
                your business towards a future of success.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}
