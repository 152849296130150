import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
      <footer className="w3l-footer-29-main">
        <div className="footer-29 py-5">
          <div className="container py-lg-4">
           
            <div className="row footer-top-29 mt-md-5 mt-4">
              <div className="col-lg-4 col-md-6 footer-list-29 footer-1">
               <Link className="navbar-brand pe-xl-5 pe-lg-4" to="index.html">
               <img src="/assets/images/logo.png" style={{height:'120px'}}></img> 
              </Link>
                <div className="footer-listw3-grids ">
                 <p > <br/><br/><br/><br/><br/>At {companyname}, we understand that if your business involves
                selling goods, import and export play a critical role in its
                success. The complexities of international trade can be
                overwhelming, and that's where our expertise as an experienced
                freight forwarding company comes into play.</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-5 pe-lg-5">
                <h6 className="footer-title-29">Links</h6>
                <div className="footer-listw3-grids">
                  <ul className="footer-listw3">
                    <li><Link to="/Home">Home</Link></li>
                    <li><Link to="/About">About</Link></li>
                    <li><Link to="/Services">Services</Link></li>
                    <li><Link to="/Contact">Contact</Link></li>
                    <li><Link to="/Privacy">Privacy Policy</Link></li>
                    <li><Link to="/Terms">Terms</Link></li>
                    <li><Link to="/Getquote">Getquote</Link></li>
                  
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-5 pe-lg-5">
                <h6 className="footer-title-29">Services</h6>
                <div className="footer-listw3-grids">
                  <ul className="footer-listw3">
                  <li>
                      <Link to="/Sea" >Sea Freight </Link>
                    </li>
                    <li>
                      <Link to="/Air" >Air Freight </Link>
                    </li>
                    <li>
                      <Link to="/Road" >Road Transport</Link>
                    </li>
                    <li>
                      <Link to="/Warehousing" >Warehousing</Link>
                    </li>
                    <li>
                      <Link to="/Project" >Project Cargo</Link>
                    </li>
                    <li>
                      <Link to="/Supply" >Supply Chain Logistics</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 footer-list-29 footer-4 mt-lg-0 mt-5 ps-lg-5">
                <h6 className="footer-title-29">Keep Connected</h6>
                <p><strong>Address:</strong> {companyaddress}</p> <br/>
                <p><strong>Phone:</strong> {companynumber}</p> <br/>

                <p><strong>Email:</strong> {companyemail}</p>

                
              </div>
            </div>
            <div className="bottom-copies text-center">
              <p className="copy-footer-29">©2023  <Link to="/Home">{companyname}</Link>. All rights reserved </p>
            </div>
          </div>
        </div>
        {/* move top */}
        <button onclick="topFunction()" id="movetop" title="Go to top">
          <span className="fa fa-angle-up" />
        </button>
        {/* //move top */}
      </footer>
    </>
  );
};

export default Footer;
